.legal-content-container {
  display: flex;
  justify-content: center;

  .legal-content {
    width: 70%;

    h1 {
      color: #000 !important;
      line-height: normal;
    }

    h2 {
      color: #000 !important;
      line-height: normal;
    }

    h3 {
      color: #000 !important;
      line-height: normal;
    }

    h4 {
      color: #000 !important;
      line-height: normal;
    }

    p,
    ul {
      line-height: normal;
      font-family: Celebes, sans-serif;
      letter-spacing: 0.01em;
      text-transform: none;
      margin-top: 0.5rem;
    }

    a {
      color: #000 !important;
      font-size: 1rem !important;
      font-family: Celebes, sans-serif;
      line-height: normal;
      letter-spacing: 0.01em;
      text-transform: none;
      text-decoration: underline !important;

      &:hover {
        color: var(--p-blue) !important;
      }
    }

    ul {
      padding-left: 2em;
    }

    h1,
    h2,
    h3,
    h4 {
      margin-top: 1rem !important;
      margin-bottom: 0.25rem !important;
      text-align: left !important;
    }
  }
}

@media (max-width: 1200px) {
  .legal-content-container {
    .legal-content {
      width: 100%;
    }

    .legal-content p,
    .legal-content a {
      font-size: 15px;
      line-height: 24px;
    }
  }
}