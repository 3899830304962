@import './color.scss';
@import './button.scss';

@mixin em-mobile {
  font-family: Celebes-SemiBold, sans-serif;
  line-height: 1.3125rem !important;
  letter-spacing: -0.2px !important;
  text-align: center !important;
}

@mixin h3-desktop {
  font-size: 1.625rem !important;
  font-family: Celebes-SemiBold, sans-serif;
  font-style: normal;
  line-height: 124% !important;
  letter-spacing: 0.00194rem;
  text-align: center;
}

@mixin h3-mobile {
  font-size: 1.25rem !important;
  letter-spacing: 0.0015rem !important;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  background-color: var(--p-white) !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  text-transform: capitalize;
  font-size: 3rem !important;
  font-family: Celebes-Bold, sans-serif;
  line-height: 116% !important;
  letter-spacing: 0.00719rem;
  text-align: center;
}

h2,
.h2 {
  text-transform: capitalize;
  font-size: 2rem !important;
  font-family: Celebes-Bold, sans-serif;
  line-height: 124% !important;
  letter-spacing: 0.00238rem;
  text-align: center;
}

h3,
.h3 {
  @include h3-desktop;
}

h4,
.h4 {
  font-size: 1.375rem !important;
  font-family: Celebes-SemiBold, sans-serif;
  font-style: normal;
  line-height: 132% !important;
  letter-spacing: 0.00163rem;
  color: var(--p-lighter-grey) !important;
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 !important;
}

p,
.p {
  font-size: 1.1875rem;
  font-family: Celebes, sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 132%;
  letter-spacing: -0.024rem;
  margin: 0 !important;
}

a,
.a {
  font-size: 1rem;
  font-family: Celebes-Bold, sans-serif;
  font-style: normal;
  line-height: 132%;
  letter-spacing: -0.00975rem;
  color: var(--p-lighter-grey) !important;
  text-decoration: none !important;
  margin: 0 !important;

  &:hover {
    color: var(--p-white) !important;
  }
}

span,
.span {
  font-size: 0.875rem;
  font-family: Celebes, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  letter-spacing: -0.015rem;
}

small,
.small {
  color: var(--p-lighter-grey);
  text-align: center;
  font-size: 0.875rem;
  font-family: Celebes, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;
  letter-spacing: -0.015rem;
  text-align: left;
}

caption,
.caption {
  font-family: Celebes-SemiBold, sans-serif !important;
  font-size: 1rem !important;
  font-style: normal;
  line-height: 132% !important;
  letter-spacing: -0.00975rem;
}

.anchor {
  position: absolute;
  top: -80px;
}

@media (max-width: 992px) {

  h1,
  .h1 {
    font-size: 2.125rem !important;
    letter-spacing: 0.00513rem !important;
  }

  h2,
  .h2 {
    font-size: 1.375rem !important;
    letter-spacing: 0.00163rem;
  }

  h3,
  .h3 {
    @include h3-mobile;
  }

  h4,
  .h4 {
    font-size: 1.0625rem !important;
    letter-spacing: 0.00125rem !important;
  }

  p,
  .p {
    font-size: 1rem !important;
    letter-spacing: -0.02rem !important;
  }

  a,
  .a {
    font-size: 0.8125rem !important;
    letter-spacing: -0.0075rem !important;
  }

  span,
  .span,
  small,
  .small {
    font-size: 0.75rem !important;
    letter-spacing: -0.0125rem !important;
  }
}