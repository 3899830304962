.review {
    border-width: 1px;
    border-style: solid;
    border-color: var(--p-paper);
    flex: 1;

    .icon-container {
        align-self: flex-start;
        align-items: center;
        width: 55px;
        height: 55px;
        padding-bottom: 2px;
        border-radius: 50px;
        justify-content: center;
    }

    h3 {
        text-align: left !important;
    }
}

@media (max-width: 1200px) {
    .review {
        margin-bottom: 1em;
    }
}