.dd-item {
  border-radius: 0.5rem;

  &:hover,
  &:active {
    color: var(--p-white) !important;
    background-color: var(--p-transparent-white);

    .a {
      color: inherit !important;
    }

    span {
      color: var(--p-lighter-grey) !important;
    }

    .icon-square,
    .icon-circle {
      background: linear-gradient(135deg, #fffaef 0%, #fff0ef 38.26%, #f6fcff 74.2%, #f2fff0 100%);

      &.icon-meta-data,
      &.icon-stars,
      &.icon-phone {
        path {
          fill: var(--p-dark-grey);
        }
      }

      &:not(.icon-meta-data, .icon-stars, .icon-phone) {
        path,
        rect {
          stroke: var(--p-dark-grey);
        }
      }
    }
  }
}
