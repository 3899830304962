.title-text {
  color: var(--p-lighter-grey, #9a9fab);
  text-transform: none !important;
}

.home-banner {
  padding: 3rem 0;
  max-height: 900px;
  height: 60vw;
  min-height: 500px;


  .h3 {
    text-transform: none !important;
  }

  .home-banner-container {
    justify-content: center;
    align-items: end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .logo {
    max-width: 75%;
  }

  .hero-background-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    padding: 0;
  }

  .unlock-header {
    padding-bottom: 12px;
  }

  .home-content {
    width: 100%;

    small {
      max-width: 30rem;
    }
  }
}

@media (max-width: 840px) {
  .home-banner {
    height: 700px;

    .home-banner-container {
      margin-left: 24pt;
      margin-right: 24pt;

      .logo {
        width: 80%;
      }
    }
  }
}