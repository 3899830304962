.icon-square,
.icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-square {
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.icon-circle {
  width: 56px;
  height: 56px;
  padding: 14px;
  border-radius: 44px;

  svg {
    width: 28px;
    height: 28px;
  }
}

.icon-default {
  svg {
    width: 24px;
    height: 24px;
  }
}

.icon-white {
  background-color: var(--p-grey);

  &.icon-stars,
  &.icon-phone {

    path,
    rect,
    circle {
      stroke: var(--p-white);
    }
  }

  &:not(.icon-stars, &.icon-phone) {

    path,
    rect,
    circle {
      stroke: var(--p-white);
    }
  }
}

.icon-dark-grey {
  &:not(.icon-meta-data, .icon-tablet) {

    path,
    rect,
    circle {
      stroke: var(--p-dark-grey);
    }
  }

  &.icon-meta-data path {
    fill: var(--p-dark-grey);
  }
}

.icon-circle,
.icon-circle {

  .icon-viewer,
  .icon-zoom {
    background-color: var(--p-dark-blue);
  }

  .icon-export {
    background-color: var(--p-dark-yellow);
  }

  .icon-markers {
    background-color: var(--p-dark-purple);
  }

  .icon-meta-data {
    background-color: var(--p-dark-green);
  }

  .icon-offline {
    background-color: var(--p-dark-red);
  }
}

.icon-map {

  &.icon-green path,
  rect,
  circle {
    stroke: var(--p-green);
  }
}

.icon-stars {

  &.icon-green path,
  rect,
  circle {
    stroke: var(--p-green);
  }

  &.icon-blue path,
  rect,
  circle {
    stroke: var(--p-blue);
  }
}