.cookie-banner {
  border-radius: 24px;
  box-shadow: 0px 0px 4px 0px #00000029;
  width: 25rem;
  bottom: 32px;
  left: 32px;
  z-index: 100;

  .h4,
  .a,
  a {
    color: var(--p-dark-grey) !important;
  }

  .a,
  a {
    text-decoration: underline !important;
  }

  .a:hover,
  a:hover {
    color: var(--p-grey) !important;
  }
}

@media (max-width: 660px) {
  .cookie-banner {
    width: 90%;
    left: 5%;
    right: 5%;
  }
}