.pill {
  border-radius: 10px;
  color: var(--p-dark-grey) !important;
  border: 1px solid;
  font-family: Celebes-SemiBold, sans-serif !important;
  text-align: center;

  &.pill-small {
    padding: 4px 12px;
  }

  &.pill-medium,
  &.pill-large {
    border-radius: 99px;
    border-width: 2px !important;
  }

  &.pill-medium {
    padding: 8px 16px;
  }

  &.pill-large {
    padding: 12px 16px;
  }
}