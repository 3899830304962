@import '../../../index.scss';

.features-item {
  padding: 3rem 1rem;

  .row {
    --bs-gutter-x: 0;

    .col-md-6 {
      width: 45%;
    }
  }

  .feature-img {
    display: flex;
    justify-content: right;
  }

  &.features-zoom .feature-img {
    object-fit: cover;

    img {
      width: 100%;
    }
  }

  &.features-store .feature-img {
    justify-content: center;
  }

  .feature-img,
  .feature-img img {
    border-radius: 3rem;
  }

  .feature-icon {
    margin-right: 1.5rem;
  }
}

.feature-text-container {
  border-radius: 3rem;
  border: 1px solid var(--p-light-grey);

  .feature-subtitle {
    margin: 0.5rem 0 !important;
  }

  .pill {
    color: var(--p-lighter-grey) !important;
  }

  .feature-text {
    padding: 0 2rem !important;
    ;
  }
}

@media (max-width: 768px) {
  .features-item {
    padding: 2rem !important;

    .col-12 {
      margin: 0.5rem 0 !important;
      padding: 0 !important;
      order: 1;
      width: 100% !important;
      justify-content: center;
      display: flex;
    }

    .feature-text-container {
      justify-content: center;
      order: 0;

      .feature-text {
        flex-direction: column;
        justify-content: center;

        h2,
        h3 {
          text-align: center !important;
          margin: 10px 0 !important;
        }

        .feature-subtitle {
          font-size: 1rem !important;
          @include em-mobile;
        }
      }

      .feature-icon {
        justify-content: center;
        margin: 20px 10px;
      }
    }

    .feature-img,
    .feature-text-container {
      margin-left: 0;
      margin-right: 0;
    }

    .pill-container {
      justify-content: center;
    }
  }
}