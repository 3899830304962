.pricing-card {
  display: flex;
  flex-grow: 1;
  width: 32%;
  max-width: 32%;
  min-width: 330px !important;
  flex-wrap: wrap;

  border-width: 2px;
  border-style: solid;
  border-radius: 1.5rem;
  margin: 1rem 0.5rem !important;

  &.sale-card {
    .card {
      color: var(--p-white);
      background-color: var(--p-dark-grey) !important;

      .h4:not(.full-price-sale) {
        color: var(--p-white) !important;
      }

      .alert.bg-dark-yellow {
        color: var(--p-yellow);
      }
    }
  }

  .card {
    margin: 4px;
    background-color: var(--p-white) !important;
    border-radius: 1.3rem !important;
    order: 0;
    position: relative;
    border: none !important;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .h4:not(.full-price-sale) {
      color: var(--p-dark-grey) !important;
    }

    .pill {
      position: absolute;
      transform: translatex(-50%);
      left: 50%;
      top: -22.5px;
      text-wrap: nowrap !important;
    }

    .pricing-card-period {
      font-family: Celebes-Bold, sans-serif;
      text-transform: uppercase;
    }

    .full-price-sale {
      text-decoration: line-through;
      font-weight: 400 !important;
    }

    .alert {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
    }
  }
}

@media (max-width: 1400px) {
  .pricing-card {
    min-width: 400px !important;

    &:not(.sale-card) {
      order: 1 !important;
    }
  }

  .sale-card {
    min-width: 815px !important;
  }
}

@media (max-width: 900px) {
  .pricing-card {
    min-width: 100% !important;
    width: 100%;
    max-width: 100%;

    .pill {
      position: relative !important;
    }
  }
}