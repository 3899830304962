.premium-support {
  flex: 1;

  .h4 {
    color: var(--p-dark-grey) !important;
  }

  p {
    color: var(--p-lighter-grey) !important;
  }
}