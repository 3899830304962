.btn {
  border: none !important;
  border-radius: 0.25rem;
  transition: none;
  padding: 12px 24px !important;

  &.btn-primary {
    background: var(
      --gradients-pearl,
      linear-gradient(135deg, #fffaef 0%, #fff0ef 38.26%, #f6fcff 74.2%, #f2fff0 100%)
    );
    color: var(--p-dark-grey) !important;

    &:hover,
    &:active,
    &:focus {
      background: var(--p-lighter-grey) !important;
    }
  }

  &.btn-secondary {
    background: var(--p-dark-grey) !important;
    color: var(--p-white) !important;
    box-shadow: 0px 8px 32px 0px rgba(20, 23, 35, 0.24);

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 16px 56px 0px rgba(20, 23, 35, 0.48);
    }
  }
}
