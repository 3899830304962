.premium-card {
  flex: 1;
  background-color: var(--p-light-grey) !important;
  border-radius: 16px !important;
  border: none !important;

  .h4 {
    color: var(--p-dark-grey) !important;
    text-align: left;
  }
}