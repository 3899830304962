.pricing-list {
  flex-wrap: wrap;
}

@media (max-width: 896px) {
  .pricing-list {
    flex-wrap: nowrap;
  }
}

@media (max-width: 900px) {
  .pricing-list {
    flex-direction: column;
    align-items: center !important;
    padding: 0 1.5rem;

    .pricing-card {
      width: 100%;
      max-width: 100%;
      margin: 1rem 0 !important;
    }
  }
}
