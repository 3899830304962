.premium-benefits-container,
.premium-support-container {
  flex-direction: row;
}

.premium-benefits-group {
  flex: 1;
}

.group-left {
  margin-right: 0.5rem;
}

.group-right {
  margin-left: 0.5rem;
}

.premium-support-container {
  border: 2px solid var(--p-light-grey);
  border-radius: 16px;
}

@media (max-width: 740px) {
  .premium-support-container {
    flex-direction: column !important;
  }
}

@media (max-width: 600px) {
  .premium-benefits-container {
    flex-direction: column !important;
  }

  .group-left,
  .group-right {
    margin: 0;
  }
}