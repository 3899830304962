.img-banner {
  justify-content: center;
  display: flex;
  overflow: hidden;
  max-height: 530px;
  position: relative;
  align-items: center;
  transition: all 0.5s;
  width: 100%;
  width: 100%;
  height: 30vw;
  min-height: 400px;

  img {
    width: 100%;
    object-fit: cover;
    min-height: 400px;
  }
}

.img-banner-container {
  padding-top: 3rem;

  h2 {
    color: var(--p-dark-grey);
  }

  h4 {
    padding-bottom: 1rem;
  }
}