:root {
  --p-white: #fff;
  --p-paper: #e3e6f0;
  --p-transparent-white: rgba(255, 255, 255, 0.02);
  --p-lighter-grey: #9a9fab;
  --p-light-grey: #edf0f2;
  --p-grey: #303742;
  --p-dark-grey: #141723;
  --p-dark-yellow: #2d2511;
  --p-yellow: #ffbd30;
  --p-transparent-yellow: #fffaef;

  --p-dark-blue: #122330;
  --p-blue: #009dd5;
  --p-transparent-blue: #f6fcff;
  --p-dark-purple: #2d1535;
  --p-purple: #d259fd;
  --p-dark-green: #132f25;
  --p-green: #00aa28;
  --p-transparent-green: #f2fff0;
  --p-dark-red: #2d1111;
  --p-red: #f4564e;
}

:export {
  white: var(--p-white);
  paper: var(--p-paper);
  transparent-white: var(--p-transparent-white);
  lighter-grey: var(--p-lighter-grey);
  light-grey: var(--p-light-grey);
  grey: var(--p-grey);
  dark-grey: var(--p-dark-grey);
  dark-yellow: var(--p-dark-yellow);
  yellow: var(--p-yellow);
  transparent-yellow: var(--p-transparent-yellow);

  dark-blue: var(--p-dark-blue);
  blue: var(--p-blue);
  transparent-blue: var(--p-transparent-blue);
  dark-purple: var(--p-dark-purple);
  purple: var(--p-purple);
  dark-green: var(--p-dark-green);
  green: var(--p-green);
  transparent-green: var(--p-transparent-green);
  dark-red: var(--p-dark-red);
  red: var(--p-red);
}

.f-white {
  color: var(--p-white) !important;
}

.bg-white {
  background-color: var(--p-white) !important;
}

.b-white {
  border-color: var(--p-white) !important;
}

.f-paper {
  color: var(--p-paper) !important;
}

.bg-paper {
  background-color: var(--p-paper) !important;
}

.b-paper {
  border-color: var(--p-paper) !important;
}

.f-dark-grey {
  color: var(--p-dark-grey) !important;
}

.bg-dark-grey {
  background-color: var(--p-dark-grey) !important;
}

.b-dark-grey {
  border-color: var(--p-dark-grey) !important;
}

.f-grey {
  color: var(--p-grey) !important;
}

.bg-grey {
  background-color: var(--p-grey) !important;
}

.b-grey {
  border-color: var(--p-grey) !important;
}

.f-light-grey {
  color: var(--p-light-grey) !important;
}

.bg-light-grey {
  background-color: var(--p-light-grey) !important;
}

.b-light-grey {
  border-color: var(--p-light-grey) !important;
}

.f-lighter-grey {
  color: var(--p-lighter-grey) !important;
}

.bg-lighter-grey {
  background-color: var(--p-lighter-grey) !important;
}

.b-lighter-grey {
  border-color: var(--p-lighter-grey) !important;
}

.f-white {
  color: var(--p-white) !important;
}

.bg-white {
  background-color: var(--p-white) !important;
}

.b-white {
  border-color: var(--p-white) !important;
}

.f-yellow {
  color: var(--p-yellow) !important;
}

.bg-yellow {
  background-color: var(--p-yellow) !important;
}

.b-yellow {
  border-color: var(--p-yellow) !important;
}

.f-transparent-yellow {
  color: var(--p-transparent-yellow) !important;
}

.bg-transparent-yellow {
  background-color: var(--p-transparent-yellow) !important;
}

.b-transparent-yellow {
  border-color: var(--p-transparent-yellow) !important;
}

.f-dark-yellow {
  color: var(--p-dark-yellow) !important;
}

.bg-dark-yellow {
  background-color: var(--p-dark-yellow) !important;
}

.b-dark-yellow {
  border-color: var(--p-dark-yellow) !important;
}

.f-blue {
  color: var(--p-blue) !important;
}

.bg-blue {
  background-color: var(--p-blue) !important;
}

.b-blue {
  border-color: var(--p-blue) !important;
}

.f-transparent-blue {
  color: var(--p-transparent-blue) !important;
}

.bg-transparent-blue {
  background-color: var(--p-transparent-blue) !important;
}

.b-transparent-blue {
  border-color: var(--p-transparent-blue) !important;
}

.f-purple {
  color: var(--p-purple) !important;
}

.f-green {
  color: var(--p-transparent-green) !important;
}

.bg-green {
  background-color: var(--p-green) !important;
}

.b-green {
  border-color: var(--p-green) !important;
}

.f-transparent-green {
  color: var(--p-transparent-green) !important;
}

.bg-transparent-green {
  background-color: var(--p-transparent-green) !important;
}

.b-transparent-green {
  border-color: var(--p-transparent-green) !important;
}