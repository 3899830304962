@import '../../index.scss';

footer {
  padding: 3rem;

  .footer-col {
    min-width: 20%;
    padding: 15px 0;

    .h4 {
      color: var(--p-paper) !important;
    }

    .info-image {
      max-width: 210px;
    }
  }

  .navigation-item {
    padding: 8px 0;
  }

  .trademarks {
    max-width: 220px;
  }

  .social-links {
    padding-top: 32px;
  }
}

@media (max-width: 1400px) {
  footer {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;

    .footer-col {
      min-width: 15%;
    }
  }
}

@media (max-width: 1070px) {
  footer {
    padding: 3rem 24px !important;

    .container-xl,
    .footer-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .navigation-item {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .social-links {
      a {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .social-links {
      padding-top: 0px;
    }
  }

  .info-image {
    align-self: center;
  }

  .footer-col {
    min-width: 100% !important;

    &.info-container {
      justify-content: center;

      small {
        max-width: 100%;
        text-align: center;
        margin: 0.25rem 0 !important;
      }

      .info-image {
        justify-content: center;
        margin: 25px 0;
      }

      .info-copyright {
        order: 3;
      }
    }

    .h4 {
      @include h3-mobile;
    }

    p,
    a {
      text-align: center !important;
    }

    &:nth-child(1) {
      order: 4;
    }
  }
}